import { useLocation, useNavigate } from 'react-router';
import BankNoteIcon from '@untitled-ui/icons-react/build/esm/BankNote03';
import DotsVerticalIcon from '@untitled-ui/icons-react/build/esm/DotsVertical';

import { Button, Dropdown } from 'antd';
import { twMerge } from 'tailwind-merge';
import Cookies from 'universal-cookie';
import { getInitialsFromCookies } from 'utils';
import { navigateToLink } from 'utils/helpers';
import { PRIME_LOGO, USER_CHECK_ICON } from 'utils/icons';

import './Navigation.scss';

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const cookies = new Cookies();
  const loggedUser = cookies.get('fullName');

  const isActive = (path: string) => location.pathname.includes(path);

  const handleSignOut = () => {
    localStorage.clear();

    cookies.remove('fullName');
    cookies.remove('userName');
    cookies.remove('password');
    cookies.remove('token');
    cookies.remove('roleId');

    window.location.href = `${navigateToLink}/login`;
  };

  const DROPDOWN_ITEMS = [
    {
      label: 'Sign Out',
      onClick: handleSignOut,
    },
  ];

  const items = DROPDOWN_ITEMS.map((item, index) => ({
    key: index.toString(),
    label: <span>{item.label}</span>,
    onClick: item.onClick,
  }));

  return (
    <aside
      id="default-sidebar"
      className="z-40 w-56 h-screen transition-transform -translate-x-full sm:translate-x-0 hidden sm:block"
      aria-label="Sidebar"
    >
      <div className="h-full px-2 py-4 overflow-y-auto bg-navy-900 min-w-[224px]">
        <div className="flex flex-col items-center h-full justify-between">
          <div className="flex flex-col w-full">
            <div className="flex items-center px-3 py-1 justify-between">
              <img src={PRIME_LOGO} alt="Prime Logo" />
            </div>

            <div className="flex items-center justify-center w-full h-[40px] mb-[16px]" />

            <ul className="space-y-2 font-medium">
              <li onClick={() => navigate('know-your-customer')}>
                <a
                  className={twMerge(
                    'nav-button',
                    `${isActive('know-your-customer') ? 'bg-navy-700' : ''}`
                  )}
                >
                  <img src={USER_CHECK_ICON} alt="Know Your Customer" />
                  <span className="ms-3">Know Your Customer</span>
                </a>
              </li>
              {/* TODO WIP */}
              <li onClick={() => navigate('billing')}>
                <a className={twMerge('nav-button', `${isActive('billing') ? 'bg-navy-700' : ''}`)}>
                  <BankNoteIcon className="text-gray-400" />
                  <span className="ms-3">Billing</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col w-full">
            <a
              href="https://compliance.primecc.com.au"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <div className="bg-navy-700 p-4 rounded-lg border border-navy-300 group hover:bg-[#1e5a9c]">
                <img src={PRIME_LOGO} alt="Prime Logo" />
              </div>
            </a>
            <div className="mt-4 flex items-center justify-between pt-4 border-solid border-t-[1px] border-[#364152] ">
              <div className="flex items-center px-3 py-2 text-[#EEF2F6] rounded-lg group font-medium text-[14px]">
                {getInitialsFromCookies(loggedUser)}
                <span className="ms-3">{loggedUser}</span>
              </div>

              <Dropdown menu={{ items }} trigger={['click']}>
                <Button type="link" className="p-0">
                  <DotsVerticalIcon className="text-gray-400 h-5 w-5" />
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

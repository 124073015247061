import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CalendarIcon from '@untitled-ui/icons-react/build/esm/Calendar';

import { DatePicker, Form, Input, Select } from 'antd';
import { FormInstance, RuleObject } from 'antd/es/form';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import { formatPhoneNumber, IndividualFormValues } from 'utils';

import './BaseForm.scss';

const { Option } = Select;

type FieldType = 'input' | 'select' | 'datePicker' | 'phoneNumber';

interface OptionType {
  label: string;
  value: string | number;
}

type FieldsRuleObject = RuleObject & { required?: boolean };

interface FieldConfig {
  type: FieldType;
  label: string;
  name: string;
  rules?: FieldsRuleObject[];
  options?: OptionType[];
  props?: React.ComponentProps<typeof Input> | React.ComponentProps<typeof Select>;
}

interface BaseFormProps {
  fields: FieldConfig[];
  onFinish?: (values: Record<string, IndividualFormValues>) => void;
  className?: string;
  form?: FormInstance<Record<string, IndividualFormValues>>;
  index?: number;
  isDisabled?: boolean;
  selectOptions?: OptionType[];
}

export const BaseForm = ({
  fields,
  onFinish,
  className,
  form: formProp,
  index,
  isDisabled = false,
  selectOptions,
}: BaseFormProps) => {
  const [form] = Form.useForm<Record<string, IndividualFormValues>>();
  const currentForm = formProp || form;
  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(false);

  const isRequired = (rules?: FieldsRuleObject[]) => rules?.some((rule) => rule.required);

  const dispatch = useDispatch();

  const checkRequiredFields = () => {
    const requiredFields = fields.filter((field) => isRequired(field.rules));
    const allRequiredFieldsFilled = requiredFields.every((field) => {
      const fieldName = !index ? `${field.name}_${index}` : field.name;
      const fieldValue = currentForm.getFieldValue(fieldName);
      return fieldValue !== undefined && fieldValue !== '';
    });

    if (allRequiredFieldsFilled !== areRequiredFieldsFilled) {
      setAreRequiredFieldsFilled(allRequiredFieldsFilled);
      dispatch(setDrawerCloseDisabled(!allRequiredFieldsFilled));
    }
  };

  return (
    <Form
      form={currentForm}
      layout="vertical"
      onFinish={onFinish}
      className={className}
      disabled={isDisabled}
      onValuesChange={checkRequiredFields}
    >
      {fields.map((field, fieldIndex) => (
        <Form.Item
          key={fieldIndex}
          label={
            <>
              <div className="text-gray-600 text-xs">{field.label}</div>
              {isRequired(field.rules) && <span className="text-gray-600 ml-1"> *</span>}
            </>
          }
          name={index !== undefined ? `${field.name}_${index}` : field.name}
          rules={field.rules}
        >
          {field.type === 'input' && (
            <Input
              {...(field.props as React.ComponentProps<typeof Input>)}
              className={`h-10 text-gray-900 font-medium ${field.props?.className || ''}`}
              maxLength={field.props?.maxLength}
            />
          )}
          {field.type === 'phoneNumber' && (
            <Input
              {...(field.props as React.ComponentProps<typeof Input>)}
              className={`h-10 text-gray-900 font-medium ${field.props?.className || ''}`}
              maxLength={12}
              onChange={(e) => {
                const formattedValue = formatPhoneNumber(e);
                const fieldName = !index ? `${field.name}_${index}` : field.name;
                currentForm.setFieldValue(fieldName, formattedValue);
              }}
            />
          )}
          {field.type === 'datePicker' && (
            <DatePicker
              format={'DD/MM/YYYY'}
              {...(field.props as React.ComponentProps<typeof DatePicker>)}
              className={twMerge(
                'overflow-hidden whitespace-nowrap text-ellipsis max-w-6 font-semibold',
                field.props?.className
              )}
              disabledDate={(current) => {
                const today = dayjs().endOf('day');
                const minDate = dayjs('1900-01-01');
                return current && (current > today || current < minDate);
              }}
              suffixIcon={
                <div className="border-l border-gray-300 h-10 flex items-center mt-0.5">
                  <CalendarIcon className="text-gray-700 h-5 w-5 ml-2.5" />
                </div>
              }
            />
          )}

          {field.type === 'select' && (
            <Select
              {...(field.props as React.ComponentProps<typeof Select>)}
              className={twMerge(
                'overflow-hidden whitespace-nowrap text-ellipsis max-w-6',
                field.props?.className
              )}
            >
              {selectOptions?.map((option, optionIndex) => (
                <Option key={optionIndex} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      ))}
    </Form>
  );
};

import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { MutationActionCreatorResult, MutationDefinition } from '@reduxjs/toolkit/query';
import PlusSquare from '@untitled-ui/icons-react/build/esm/PlusSquare';

import { Divider, Spin } from 'antd';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { useAppDispatch } from 'app-redux/hooks';
import { BaseButton, SearchInput } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import dayjs from 'dayjs';
import { GetEntityModel, GetPersonModel } from 'solstice-types';
import { useDebounce } from 'use-debounce';
import { UserModel } from 'utils';
import { AddNewIndividualForm, SelectedIndividual } from './components';

interface AddNewIndividualContentProps {
  searchIndividual: (
    params: any
  ) => MutationActionCreatorResult<MutationDefinition<any, any, any, GetPersonModel[], 'primeApi'>>;
  data?: GetPersonModel[];
  isLoading?: boolean;
}

export const AddNewIndividualContent = ({
  searchIndividual,
  data = [],
  isLoading,
}: AddNewIndividualContentProps) => {
  const [selectedIndividual, setSelectedIndividual] = useState<any | null>(null);
  const [areInputsVisible, setAreInputsVisible] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isExistingVisible, setIsExistingVisible] = useState<boolean>(false);
  const [isSearchComplete, setIsSearchComplete] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    //getValues,
    setValue,
    reset,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'individuals',
  });

  const dispatch = useAppDispatch();
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  useEffect(() => {
    if (!debouncedSearchQuery) {
      setIsSearchComplete(false);
      return;
    }

    const fetchData = async () => {
      setIsSearchComplete(false);
      try {
        await searchIndividual({ searchTerm: debouncedSearchQuery });
        setIsSearchComplete(true);
      } catch {
        setIsSearchComplete(true);
      }
    };

    fetchData();
  }, [debouncedSearchQuery, searchIndividual]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    if (!value.trim()) {
      setIsSearchComplete(false);
    }
  };

  const searchOptions =
    isSearchComplete && searchQuery && data.length === 0
      ? [
          {
            value: 'no-result',
            label: <div className="text-gray-500 font-medium">No results found.</div>,
          },
        ]
      : searchQuery
        ? data.map((user: UserModel) => ({
            value: user.id,
            label: <SelectedIndividual user={user} searchQuery={searchQuery} areOptionsVisible />,
          }))
        : [];

  const handleSelect = (value: string) => {
    if (value === 'no-result') return;

    setIsExistingVisible(true);
    setAreInputsVisible(false);
    dispatch(setDrawerCloseDisabled(false));

    const selectedItem = data.find((item: GetEntityModel) => item.id === value);
    const { firstName, lastName, mobilePhone, email, dateOfBirth, middleName } = selectedItem ?? {};

    if (selectedItem) {
      setSelectedIndividual(selectedItem);
      setSearchQuery('');

      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('mobilePhone', mobilePhone);
      setValue('email', email);
      setValue('middleName', middleName);

      const parsedDate = dayjs(dateOfBirth);
      if (parsedDate.isValid()) {
        setValue('dateOfBirth', parsedDate);
      }
    }
  };
  const handleRemoveIndividual = (index: number) => {
    remove(index);

    reset();

    setAreInputsVisible(false);
    setSearchQuery('');
    setSelectedIndividual(null);
    setIsExistingVisible(false);
    dispatch(setDrawerCloseDisabled(false));
  };
  const handleRemoveSelected = () => {
    if (!selectedIndividual) setAreInputsVisible(!areInputsVisible);
    reset();
    setSearchQuery('');
    setSelectedIndividual(null);
    setIsExistingVisible(false);
    dispatch(setDrawerCloseDisabled(true));
  };
  const handleDisplayInputs = () => {
    if (!selectedIndividual) setAreInputsVisible(!areInputsVisible);
    reset();
    setSearchQuery('');
    setSelectedIndividual(null);
    setIsExistingVisible(false);
    dispatch(setDrawerCloseDisabled(true));
    append({
      firstName: '',
      lastName: '',
      niddleName: '',
      dateOfbirth: '',
      email: '',
      mobilePhone: '',
      relationship: '',
    });
  };

  return (
    <>
      <span className="text-gray-600">Search for an existing individual or add a new one.</span>
      <SearchInput
        placeholder="Type to search..."
        onSearch={handleSearch}
        onSelect={handleSelect}
        value={searchQuery}
        options={searchOptions}
        disabled={areInputsVisible}
        suffixIcon={
          isLoading ? (
            <Spin className="mt-2" size="default" indicator={<LoadingOutlined spin />} />
          ) : (
            ''
          )
        }
      />

      {selectedIndividual && (
        <SelectedIndividual
          user={selectedIndividual}
          searchQuery={searchQuery}
          isExistingVisible={isExistingVisible}
          handleDisplayInputs={handleRemoveSelected}
        />
      )}
      {!selectedIndividual && (
        <BaseButton
          className="link-button"
          onClick={handleDisplayInputs}
          hidden={areInputsVisible}
          disabled={isExistingVisible}
        >
          <PlusSquare width={15} height={15} />
          Add New Individual
        </BaseButton>
      )}

      {fields?.map((field, index) => (
        <div key={field.id}>
          <div className="text-gray-600 font-semibold text-base flex items-center justify-between w-full">
            Add New Individual
            <DeleteButton onClick={() => handleRemoveIndividual(index)} />
          </div>

          <AddNewIndividualForm
            control={control}
            errors={errors}
            formIndex={100}
            hidden={true}
            setValue={setValue}
          />
        </div>
      ))}

      <Divider className="m-0" />
    </>
  );
};

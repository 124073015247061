import FileCheck02 from '@untitled-ui/icons-react/build/esm/FileCheck02';

import { notification, Tooltip } from 'antd';
import { BaseButton } from './BaseButton';

interface RecordType {
  kycLoginUrl: string;
}

interface RedirectButtonProps {
  record: RecordType;
}

export const RedirectButton = ({ record }: RedirectButtonProps) => {
  const handleRedirect = () => {
    if (!record.kycLoginUrl) {
      notification.warning({
        message: 'No URL found.',
        placement: 'top',
      });
      return;
    }

    window.open(record.kycLoginUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Tooltip title="Redirect to Verification Page">
      <BaseButton
        className="secondary-button min-h-8 min-w-8"
        icon={<FileCheck02 className="h-4 w-4" />}
        onClick={handleRedirect}
      />
    </Tooltip>
  );
};

export default RedirectButton;

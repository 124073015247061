import { ChangeEvent } from 'react';
import { Controller, FieldValues, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import CalendarIcon from '@untitled-ui/icons-react/build/esm/Calendar';

import { DatePicker, Input, Select } from 'antd';
import { endOfToday, isAfter, isBefore } from 'date-fns';
import { formatPhoneNumber } from 'utils';

const { Option } = Select;

const disabledDate = (current: any) => {
  const minDate = new Date(1900, 0, 1);
  return (
    current && (isAfter(current.toDate(), endOfToday()) || isBefore(current.toDate(), minDate))
  );
};
export const formIndividualFields = [
  {
    name: 'firstName',
    label: 'First Name',
    component: Input,
    rules: { required: 'First Name is required' },
    className: 'min-w-[194px]',
  },
  {
    name: 'middleName',
    label: 'Middle Name',
    component: Input,
    className: 'min-w-[194px]',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    component: Input,
    rules: { required: 'Last Name is required' },
    className: 'min-w-[194px]',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    component: DatePicker,
    placeholder: '',
    suffixIcon: (
      <div className="border-l border-gray-300 h-10 flex items-center mt-0.5">
        <CalendarIcon className="text-gray-700 h-5 w-5 ml-2.5" />
      </div>
    ),
    rules: { required: 'Field required' },
    disabledDate: disabledDate,
    className: 'min-w-[194px]',
  },
  {
    name: 'email',
    label: 'Email',
    component: Input,
    rules: {
      required: 'Field required',
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address',
      },
    },
    className: 'min-w-[194px]',
  },
  {
    name: 'mobilePhone',
    label: 'Mobile Phone',
    component: Input,
    maxLength: 12,
    rules: {
      required: 'Mobile phone is required',
      pattern: {
        value: /^(04|05)[0-9]{2}\s[0-9]{3}\s[0-9]{3}$/,
        message: 'Format should be 05XX XXX XXX',
      },
    },
    className: 'min-w-[194px]',
  },
  {
    name: 'relationship',
    label: 'Relationship',
    component: Select,
    rules: { required: 'Relationship is required' },
    isSelect: true,
    className: 'min-w-[302px]',
    placeholder: <div className="text-gray-900 font-semibold">Select</div>,
  },
];

// {
//   type: 'phoneNumber',
//   label: 'Mobile Phone',
//   name: 'mobilePhone',
//   rules: [
//     { required: true, message: 'This field is required' },
//     {
//       pattern: /^(04|05)\d{2}\s?\d{3}\s?\d{3}$/,
//       message: '',
//     },
//     {
//       validator: (_: any, value: string) => {
//         if (!value) return Promise.resolve();
//         const digits = value.replace(/\D/g, '');
//         if (digits.length === 10 && (digits.startsWith('04') || digits.startsWith('05'))) {
//           return Promise.resolve();
//         }
//         return Promise.reject('04XX XXX XXX or 05XX XXX XXX');
//       },
//     },
//   ],
//   props: { className: 'min-w-[194px]', maxLength: 10 },
// },

interface AddNewIndividualFormProps {
  control: UseFormReturn['control'];
  errors: Record<string, any>;
  relationshipOptions?: any;
  formIndex?: number;
  hidden?: boolean;
  setValue: UseFormSetValue<FieldValues>;
}

export const AddNewIndividualForm = ({
  control,
  errors,
  relationshipOptions,
  formIndex = 0,
  hidden = false,
  setValue,
}: AddNewIndividualFormProps) => (
  <div className="flex flex-wrap items-center justify-center w-full gap-5 bg-gray-100 p-[14px] rounded-lg mt-4">
    {formIndividualFields?.map((field) => {
      const isRequired = field.rules?.required;
      const fieldName = `${field.name}_${formIndex}`;

      if (field.name === 'relationship' && hidden) {
        return null;
      }

      return (
        <div key={fieldName}>
          <label className="block mb-1 text-gray-600 font-semibold text-xs">
            {field.label} {isRequired && '*'}
          </label>

          <Controller
            name={fieldName}
            control={control}
            rules={field.rules}
            render={({ field: controllerField }) => {
              const Component: React.ElementType = field.isSelect ? Select : field.component;

              return (
                <Component
                  {...controllerField}
                  placeholder={field.placeholder}
                  suffixIcon={field.suffixIcon}
                  className={`h-10 ${field.className || ''} font-medium`}
                  status={errors[fieldName] ? 'error' : ''}
                  disabledDate={field.disabledDate}
                  rules={field.rules}
                  format="DD/MM/YYYY"
                  maxLength={field.maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement> | any) => {
                    if (field.name === 'mobilePhone') {
                      const formattedValue = formatPhoneNumber(e.target?.value || '');
                      setValue(fieldName, formattedValue);
                    } else if (field.name === 'dateOfBirth') {
                      setValue(fieldName, e || null);
                    } else if (field.name === 'relationship') {
                      setValue(fieldName, e);
                    } else {
                      setValue(fieldName, e.target?.value || '');
                    }
                  }}
                >
                  {field.isSelect &&
                    relationshipOptions?.map((relationship: any) => (
                      <Option key={relationship.value} value={relationship.value}>
                        {relationship.label}
                      </Option>
                    ))}
                </Component>
              );
            }}
          />
        </div>
      );
    })}
  </div>
);

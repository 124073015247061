/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { notification } from 'antd';
import { setCurentStep, setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { useGetEntityRolesQuery, usePrimeSearchMutation } from 'api/EntitiesApiSlice';
import { useAddIndividualMutation } from 'api/IndividualsApiSlice';
import { useAppDispatch, useAppSelector } from 'app-redux/hooks';
import { useModal } from 'components';
import { useDrawer } from 'components/Drawer/Drawer';
import { ModalComponent } from 'components/Modal/Modal';
import { format } from 'date-fns';
import { Owner } from 'solstice-types';
import { removeWhiteSpaces } from 'utils';
import { IndividualDrawerFooter } from '../AddNewIndividual/IndividualDrawerFooter';
import { AddCompanyIndividualsStep } from './Steps/AddCompanyIndividualsStep';
import { AddCompanyStep } from './Steps/AddCompanySteps';
import { EntitySearchStep } from './Steps/EntitySearchStep';

enum Step {
  SEARCH,
  ADD_COMPANY,
  ADD_INDIVIDUALS,
  REVIEW,
}

export interface OwnerWithEmail extends Owner {
  email?: string;
}

export const CompanyVerificationFlow = () => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.SEARCH);
  const [stepHistory, setStepHistory] = useState<Step[]>([Step.SEARCH]);
  const [forceStorePerson, setForceStorePerson] = useState<boolean>(false);

  const [searchPrime, { data: primeData, isLoading: isGroupSearchLoading }] =
    usePrimeSearchMutation();
  const { data: entityRoles } = useGetEntityRolesQuery({ type: 'Company' });
  const [addIndividuals, { isLoading: areIndividualsLoading }] = useAddIndividualMutation();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    getValues,
    reset,
  } = methods;

  const { closeDrawer } = useDrawer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { existingCompany, uboReportData, abnIdentifier } = useAppSelector((state) => state.groups);

  const { openModal, closeModal } = useModal();
  const modalBodyText =
    'You are about to cancel and all progress will be lost. Any data you entered for the new company will be lost. Are you sure you want to proceed?';

  const handleAsicSearchSelection = () => {
    openModal(
      <ModalComponent
        bodyContent={
          <div>
            ASIC Searches are a single level search and may not fulfill your compliance obligations
            in identifying ultimate beneficial owners. To run a recursive UBO report, please select
            the Run UBO option. <br />
            <br /> Are you sure you wish to continue with a Single Level ASIC Search?
          </div>
        }
        onCancel={closeModal}
        onUpdate={() => {
          goToNextStep(Step.ADD_INDIVIDUALS);

          closeModal();
        }}
        buttonText="Continue"
      />,
      'ASIC Search'
    );
  };

  const handleCloseModal = () => {
    setCurrentStep(0);
    dispatch(setCurentStep(0));
    dispatch(setDrawerCloseDisabled(true));
    reset();
    closeModal();
  };

  const handleDisplayModal = () => {
    openModal(
      <ModalComponent
        bodyContent={modalBodyText}
        onCancel={closeModal}
        onUpdate={handleCloseModal}
        buttonText="Confirm"
      />,
      'Are You Sure?'
    );
  };

  const goToPreviousStep = () => {
    openModal(
      <ModalComponent
        bodyContent={modalBodyText}
        onCancel={closeModal}
        onUpdate={() => {
          setCurrentStep(1);
          dispatch(setCurentStep(1));
          dispatch(setDrawerCloseDisabled(true));

          closeModal();
        }}
        buttonText="Confirm"
      />,
      'Are You Sure?'
    );
  };

  const goToNextStep = async (nextStep: Step) => {
    setCurrentStep(nextStep);
    dispatch(setCurentStep(nextStep));
    setStepHistory((prevHistory) => [...prevHistory, nextStep]);
  };

  const renderStep = () => {
    switch (currentStep) {
      case Step.SEARCH:
        return (
          <EntitySearchStep
            setStep={goToNextStep}
            isLoading={isGroupSearchLoading}
            type="Company"
            searchGroup={searchPrime}
            primeData={primeData}
          />
        );
      case Step.ADD_COMPANY:
        return <AddCompanyStep handleDisplayModal={handleDisplayModal} />;
      case Step.ADD_INDIVIDUALS:
        return (
          <AddCompanyIndividualsStep
            setStep={goToNextStep}
            entityRoles={entityRoles}
            isExistingCompany={!!existingCompany}
            handleDisplayModal={handleDisplayModal}
          />
        );
      default:
        return null;
    }
  };

  const mappedOwners = (
    Array.isArray(uboReportData)
      ? uboReportData
      : uboReportData?.report?.ubo?.beneficialOwners?.owners || []
  ).map((owner: any) => ({
    firstName: owner.name?.firstName || owner?.firstName,
    middleName: owner.name?.middleName || owner.middleName,
    lastName: owner.name?.lastName || owner?.lastName,
    entityId: uboReportData?.entityId || abnIdentifier.entityId || '',
    ownershipPercentage: Number(owner.ownership) || Number(owner.ownershipPercentage),
    mobilePhone: owner.mobilePhone || '',
    roleId: '01a65107-6ce0-479b-81d5-159b49e3730c',
    forceStore: true,
    sharesBeneficiallyHeld: owner.sharesBeneficiallyHeld,
    email: owner.email,
    country: 'AU',
    postcode: '2259',
    state: 'NSW',
    streetName: 'Oriana Street',
    streetNumber: '33',
    streetType: 'STREET',
    suburb: 'Yarramalog',
  }));

  const handleSave = async (forceStoreOverride?: boolean) => {
    const forceStore = forceStoreOverride ?? forceStorePerson;
    const formValues = getValues();

    if (currentStep === Step.SEARCH && existingCompany) {
      goToNextStep(Step.ADD_INDIVIDUALS);
    } else if (currentStep === Step.ADD_COMPANY && formValues.apiCheckSelect === 'asicSearch') {
      handleAsicSearchSelection();
    } else if (currentStep === Step.ADD_COMPANY && formValues.apiCheckSelect === 'uboCheck') {
      goToNextStep(Step.ADD_INDIVIDUALS);
    } else {
      const requiredFields = [
        'firstName',
        'lastName',
        'email',
        'ownershipPercentage',
        'entityId',
        'roleId',
        'dateOfBirth',
      ];

      const formattedData = Object.keys(formValues)
        .filter((key) => key.startsWith('firstName_'))
        .map((key) => {
          const i = key.split('_')[1];
          const dateOfBirth = formValues[`dateOfBirth_${i}`];
          return {
            firstName: formValues[`firstName_${i}`] || '',
            lastName: formValues[`lastName_${i}`] || '',
            middleName: formValues[`middleName_${i}`] || '',
            email: formValues[`email_${i}`] || '',
            mobilePhone: removeWhiteSpaces(formValues[`mobilePhone_${i}`] || ''),
            dateOfBirth: dateOfBirth ? format(new Date(dateOfBirth), 'yyyy-MM-dd') : '2000-01-01',
            ownershipPercentage: Number(formValues[`ownership_${i}`]) || 0,
            entityId: uboReportData?.entityId || abnIdentifier.entityId || '',
            roleId: formValues[`role_${i}`] || '',
            forceStore: forceStore,
            country: 'AU',
            postcode: '2259',
            state: 'NSW',
            streetName: 'Oriana Street',
            streetNumber: '33',
            streetType: 'STREET',
            suburb: 'Yarramalog',
          };
        })
        .filter((data) => data !== undefined);

      if (formattedData.length === 0 && mappedOwners.length === 0) {
        notification.error({
          message: 'No valid individuals to add. Please fill the fields.',
          className: 'custom-error-message',
          placement: 'top',
          icon: <></>,
        });
        return;
      }

      try {
        await addIndividuals({
          newIndividual: [...formattedData, ...mappedOwners],
          isGroup: true,
        })
          .unwrap()
          .then(() => {
            navigate(`/entity-details/${uboReportData?.entityId ?? abnIdentifier.entityId}`);

            notification.success({
              message: 'Link generated successfully!',
              className: 'custom-success-message',
              placement: 'top',
              icon: <></>,
            });
            closeDrawer();
          });
      } catch (error: any) {
        
        let errorMessage = '';

        if (error?.data) {
          if (typeof error.data === 'string') {
            errorMessage = error.data.toLowerCase().trim();
          } else if (typeof error.data === 'object') {
            errorMessage = JSON.stringify(error.data, null, 2);
          }
        }

        if (errorMessage.includes('email')) {
          openModal(
            <ModalComponent
              bodyContent={modalBodyText}
              isLoading={areIndividualsLoading}
              onCancel={closeModal}
              onUpdate={() => {
                setForceStorePerson((prev) => !prev);
                closeModal();

                setTimeout(() => {
                  handleSave(!forceStorePerson);
                }, 2000);
              }}
            />,
            'Duplicated Entity'
          );
        } else {
          notification.error({
            message: 'A general error has occurred, please contact IT support for assistance.',
            className: 'custom-error-message',
            placement: 'top',
            icon: <></>,
          });
        }
      }
    }
  };
  const renderButtonText = () =>
    currentStep === Step.ADD_INDIVIDUALS ? 'Start Verification' : 'Continue';

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(() => handleSave())}>
        {renderStep()}
        <IndividualDrawerFooter
          confirmButtonText={renderButtonText}
          handleSave={handleSubmit(() => handleSave(forceStorePerson))}
          handlePreviousStep={goToPreviousStep}
          hasSteps={currentStep === Step.ADD_INDIVIDUALS}
          isLoading={areIndividualsLoading}
          hideFooter={currentStep === Step.REVIEW}
          // isStartDisabled={!isValid}
        />
      </form>
    </FormProvider>
  );
};

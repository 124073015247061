import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BuildingIcon from '@untitled-ui/icons-react/build/esm/Building02';
import CalendarIcon from '@untitled-ui/icons-react/build/esm/CalendarPlus01';
import ChevronRight from '@untitled-ui/icons-react/build/esm/ChevronRight';
import EyeIcon from '@untitled-ui/icons-react/build/esm/Eye';
import HomeLine from '@untitled-ui/icons-react/build/esm/HomeLine';

import { Breadcrumb, notification, Select, Spin } from 'antd';
import { useGetEntityByIdQuery, useUpdateTrustTypeMutation } from 'api/EntitiesApiSlice';
import { useLazyGetDocumentByIdQuery } from 'api/TrustApiSlice';
import { BaseButton, Icon, StatusTag, TableComponent, useModal } from 'components';
import { format } from 'date-fns';
import { GetPersonModel } from 'solstice-types';
import { getFullName } from 'utils';
import { companyDetailsColumns, companyIndividualsColumns } from 'utils/constants';
import { trustTypesOptions } from 'utils/EntityTypes';
import { DIVIDER_VERTICAL_ICON } from 'utils/icons';
import { StatusChangeModal } from './components/StatusChangeModal';

export const CompanyDetails = () => {
  const trustOptions = trustTypesOptions?.map((item) => ({
    value: item.value,
    label: item.label,
  }));

  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const { id = '' } = useParams<{ id: string }>();
  const { data: entityData, isLoading } = useGetEntityByIdQuery({ id });
  const [getDocumentById] = useLazyGetDocumentByIdQuery();
  const [updateTrustType, { isLoading: isTrustTypeLoading }] = useUpdateTrustTypeMutation();

  const {
    abn,
    acn,
    entityTypeName,
    location: groupLocation,
    registeredName,
    status = null,
    documents = [],
    persons = [],
    createdAt,
    entityRegisteredTypeId,
    kycStatus,
    assignedToUser,
  } = entityData ?? {};

  const displayEntityTypeName = entityTypeName === 'Trust' ? 'Trust/Other' : 'Company';

  const handleUpdateTrustType = (typeId: any) => {
    try {
      updateTrustType({ registeredTypeId: typeId, entityId: id })
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Trust type has been updated successfully.',
            className: 'custom-success-message',
            placement: 'top',
            icon: <></>,
          });
        });
    } catch {
      notification.error({
        message: 'Failed to update trust type.',
        className: 'custom-error-message',
        placement: 'top',
        icon: <></>,
      });
    }
  };

  const handleDisplayModal = useCallback(
    (record: GetPersonModel | null) => {
      if (!record) return;

      openModal(
        <StatusChangeModal selectedIndividual={record} lastKycId={record.lastKycFlowId ?? ''} />
      );
    },
    [openModal, closeModal]
  );

  const handleOpenFile = async (file: any) => {
    try {
      const response = await getDocumentById({ documentId: file.id });

      if (response.data && 'url' in response.data) {
        window.open(response.data.url, '_blank');
        return;
      }

      if ('data' in response && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');

        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
        }, 100);
      }
    } catch {
      //TODO: Handle error
    }
  };

  const entityDetailsName = entityTypeName === 'Trust' ? 'Trust' : 'Company';
  const isTrustEntityType = entityTypeName === 'Trust';
  const createdAtFormat = createdAt
    ? format(new Date(Date.parse(createdAt)), 'dd/MM/yyyy - hh:mm a')
    : '';

  if (isLoading) {
    return <Spin fullscreen className="translate-x-16" />;
  }

  return (
    <div className="pt-8 h-screen">
      <div className="w-full border-b px-5 pb-6">
        <Breadcrumb
          separator={<ChevronRight className="h-4 w-4 text-gray-300 mt-1 mx-1" />}
          className="text-gray-600"
          items={[
            {
              title: <HomeLine className="h-5 w-5 text-gray-500" />,
              href: '/',
            },
            {
              title: 'Know Your Customer',
              href: '/know-your-customer',
              className: 'text-gray-600 font-medium',
            },
            {
              title: registeredName,
              className: 'font-semibold text-blue-600',
            },
          ]}
        />
        <div className="mt-5 flex justify-between font-bold text-2xl text-gray-800">
          <div className=" flex items-center gap-2.5">
            {registeredName}
            <StatusTag status={kycStatus ?? ''} />
          </div>

          <div className="flex gap-2.5">
            <div className="text-gray-600 text-xs font-semibold" hidden={!isTrustEntityType}>
              Type:
              <Select
                className="pl-2.5 min-w-40 h-10"
                options={trustOptions}
                onChange={handleUpdateTrustType}
                defaultValue={entityRegisteredTypeId}
                loading={isTrustTypeLoading}
              />
            </div>

            <div hidden={!isTrustEntityType}>
              <Icon src={DIVIDER_VERTICAL_ICON} alt="divider" className="mt-2" />
            </div>

            <BaseButton
              className="secondary-button"
              onClick={() => navigate(`/entity-details/${id}/audit-log`)}
            >
              View Audit Log
            </BaseButton>
            {/* <BaseButton hidden={isTrustEntityType}> Download Full UBO Report</BaseButton> */}
          </div>
        </div>
        <div className="text-gray-600 text-sm font-normal flex items-center gap-2">
          <CalendarIcon className="h-4 w-4 text-gray-400" />
          <span> Added on: {createdAtFormat} </span>
          <BuildingIcon className="h-4 w-4 text-gray-400 ml-1" /> {displayEntityTypeName}
        </div>
      </div>

      <div className="w-full p-2.5 bg-gray-100 overflow-y-auto h-[calc(100vh-162px)]">
        <div className="w-full p-4 bg-white rounded-lg flex flex-col">
          <div className="flex flex-col">
            <span className="font-semibold text-base text-gray-600">ID Verification</span>
            <span className="text-gray-600 text-sm pb-5 pt-1">
              Once the verification is complete, you will be able to see the full report here.
              Warning: the verification link is available for 48 hours only!
            </span>
          </div>

          {persons?.map((individual, index) => (
            <div key={index} className="mb-4">
              <span className="text-gray-600 font-semibold text-sm mb-2.5 block">
                {getFullName(individual.firstName, individual.lastName)}
              </span>

              <TableComponent
                columns={companyDetailsColumns(handleDisplayModal)}
                dataSource={[
                  {
                    ...individual,
                    assignedToUser,
                    kycLoginUrl: entityData?.kycLoginUrl || individual.kycLoginUrl || undefined,
                    id: individual.id ?? '',
                  },
                ]}
                hasPagination={false}
                className="overflow-hidden"
              />
            </div>
          ))}
        </div>

        <div
          className="w-full p-4 mt-2.5 bg-white rounded-lg flex flex-col"
          hidden={!isTrustEntityType}
        >
          <span className="font-semibold text-base text-gray-600">Attached Documents</span>
          <span className="text-gray-600 text-sm pb-5 pt-1">
            {documents?.length !== 0
              ? 'The following are the documents uploaded in the verification process.'
              : 'No documents available.'}
          </span>

          <div className={`grid ${documents?.length === 1 ? 'grid-cols-1' : 'grid-cols-2'} gap-4`}>
            {documents?.map((file, index) => (
              <div
                key={index}
                className="bg-gray-100 flex items-center justify-between rounded-lg py-2.5 px-2"
              >
                <span className="text-gray-900">{file?.originalFileName}</span>
                <BaseButton
                  className="min-w-8 min-h-8 p-1"
                  onClick={() => handleOpenFile(file)}
                  type="link"
                >
                  <EyeIcon />
                </BaseButton>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full p-4 mt-2.5 bg-white rounded-lg flex flex-col">
          <span className="font-semibold text-base text-gray-600">Beneficial Owners</span>
          <span className="text-gray-600 text-sm pb-5 pt-1">
            The following individuals directly or indirectly own 25% or more of this entity.
          </span>

          <TableComponent
            columns={companyIndividualsColumns}
            dataSource={persons as GetPersonModel[]}
            hasPagination={false}
          />
        </div>

        <div
          className="w-full p-4 mt-2.5 bg-white rounded-lg flex flex-col"
          hidden={isTrustEntityType}
        >
          <span className="font-semibold text-base text-gray-600">{entityDetailsName} Info</span>
          <span className="text-gray-600 text-sm pb-5 pt-1">
            Summary information found on this organisation.
          </span>

          <div className="bg-gray-50 gap-16 text-gray-500 p-5 font-normal text-xs rounded-lg h-fit w-full flex">
            <div className="flex gap-5 min-w-[538px]">
              <div className="flex flex-col gap-5">
                <span className="">ACN</span>
                <span className="">ABN</span>
                <span className="">Registration Date</span>
                <span className="">Status</span>
              </div>

              <div className="flex flex-col font-semibold text-gray-800 gap-5">
                <span className="uppercase">{acn ?? '-'}</span>
                <span className="">{abn ?? '-'}</span>
                <span className="">-</span>
                <span className="">{status ?? '-'}</span>
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex flex-col gap-5">
                <span className="">Type</span>
                <span className="">Class</span>
                <span className="">Sub-class</span>
                <span className="">Locality</span>
              </div>

              <div className="flex flex-col font-semibold text-gray-800 gap-5">
                <span className="uppercase">{entityTypeName}</span>
                <span className="">-</span>
                <span className="">-</span>
                <span className="">{groupLocation ?? '-'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PlusSquare from '@untitled-ui/icons-react/build/esm/PlusSquare';

import { Spin } from 'antd';
import { BaseButton, SearchInput } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { GetPersonModel } from 'solstice-types';
import { useDebounce } from 'use-debounce';
import { UserModel } from 'utils';
import { AddNewIndividualForm } from './AddNewIndividualForm';
import { relationshipIndividualOptions } from './IndividualConstants';
import { SelectedIndividual } from './SelectedIndividual';

interface AddNewAssociatedPartyContentProps {
  searchIndividual: (searchTerm: any) => void;
  data: GetPersonModel[];
  isLoading?: boolean;
  selectedIndividual: UserModel | null;
  setSelectedIndividual: (user: UserModel | null) => void;
}

export const AddNewAssociatedPartyContent = ({
  searchIndividual,
  data,
  isLoading,
  selectedIndividual,
  setSelectedIndividual,
}: AddNewAssociatedPartyContentProps) => {
  const {
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'associatedParty',
  });

  const handleAddNewIndividual = () => {
    append({
      firstName: '',
      lastName: '',
      middleName: '',
      dateOfbirth: '',
      email: '',
      mobilePhone: '',
      relationship: '',
      country: 'AU',
      postcode: '2259',
      state: 'NSW',
      streetName: 'Oriana Street',
      streetNumber: '33',
      streetType: 'STREET',
      suburb: 'Yarramalog',
    });
  };
  const handleRemoveIndividual = (index: number) => {
    const currentIndividual = getValues('newAssociatedParty') || [];
    const updatedIndividual = currentIndividual.filter((_: any, i: number) => i !== index);
    setValue('newAssociatedParty', updatedIndividual);

    const updatedFields = Object.entries(getValues()).reduce<Record<string, any>>(
      (acc, [key, value]) => {
        if (key.includes(`_${index}`)) {
          return acc;
        }

        const adjustedKey = key.replace(new RegExp(`_(\\d+)`), (_, idx) =>
          idx > index ? `_${idx - 1}` : `_${idx}`
        );

        acc[adjustedKey] = value;
        return acc;
      },
      {}
    );

    reset(updatedFields);

    remove(index);
  };

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isExistingVisible, setIsExistingVisible] = useState<boolean>(false);
  const [relationshipValue, setRelationshipValue] = useState<string>('');

  const [debouncedQuery] = useDebounce(searchQuery, 800);

  const searchOptions = isLoading
    ? [
        {
          value: 'loading',
          label: (
            <div className="flex items-center justify-center">
              <Spin size="small" />
              <span className="ml-2">Loading...</span>
            </div>
          ),
        },
      ]
    : searchQuery && data?.length > 0
      ? data.map((user: UserModel) => ({
          value: user.id,
          label: <SelectedIndividual user={user} searchQuery={searchQuery} areOptionsVisible />,
        }))
      : searchQuery && data?.length === 0
        ? [
            {
              value: 'no-result',
              label: <div className="text-gray-500 font-medium">No results found.</div>,
            },
          ]
        : [];

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery) {
        try {
          await searchIndividual({ searchTerm: debouncedQuery });
        } catch {
          //
        }
      }
    };

    fetchData();
  }, [debouncedQuery, searchIndividual]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const handleSelect = (value: string) => {
    if (value === 'no-result') return;
    setIsExistingVisible(true);
    const selectedItem = data?.find((item) => item.id === value);

    if (selectedItem) {
      setSelectedIndividual({ ...selectedItem, relationship: relationshipValue });
      setSearchQuery('');
    }
  };

  const handleSelectRelationship = (value: string) => {
    setRelationshipValue(value);
    setSelectedIndividual({ ...selectedIndividual, relationship: value });
  };

  const handleSelectedIndividual = () => {
    setIsExistingVisible(false);
    setSearchQuery('');
    setSelectedIndividual(null);
  };

  return (
    <>
      <span className="text-gray-600">
        Is this individual related to somebody inside Prime Compliance? You can search for an
        existing individual or add a new one.
      </span>

      <SearchInput
        placeholder="Type to search..."
        onSearch={handleSearch}
        onSelect={handleSelect}
        value={searchQuery}
        options={searchOptions}
      />

      {selectedIndividual && (
        <SelectedIndividual
          user={selectedIndividual}
          searchQuery={searchQuery}
          isExistingVisible={isExistingVisible}
          handleDisplayInputs={handleSelectedIndividual}
          isStatusVisible={false}
          isSelectVisible
          selectOptions={relationshipIndividualOptions}
          onSelect={handleSelectRelationship}
        />
      )}

      {fields?.map((field, index) => (
        <div key={field.id}>
          <div className="text-gray-600 font-semibold text-base flex items-center justify-between w-full">
            Person #{index + 1}
            <DeleteButton onClick={() => handleRemoveIndividual(index)} />
          </div>

          <AddNewIndividualForm
            control={control}
            errors={errors}
            relationshipOptions={relationshipIndividualOptions}
            formIndex={index}
            setValue={setValue}
          />
        </div>
      ))}

      <BaseButton type="link" className="link-button" onClick={handleAddNewIndividual}>
        <PlusSquare width={15} height={15} />
        Add Associated Party
      </BaseButton>
    </>
  );
};

import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import CalendarDate from '@untitled-ui/icons-react/build/esm/CalendarDate';
import DotsVerticalIcon from '@untitled-ui/icons-react/build/esm/DotsVertical';
import HomeLine from '@untitled-ui/icons-react/build/esm/HomeLine';

import { DatePicker } from 'antd';
import { useGetAuditLogByIdQuery } from 'api/EntitiesApiSlice';
import { BaseSelect, Breadcrumb, TableComponent } from 'components';
import { endOfDay, isWithinInterval, parseISO, startOfDay } from 'date-fns';
import { auditLogColumns } from 'utils/constants';

export const AuditLog = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: auditLogData, isLoading } = useGetAuditLogByIdQuery(id);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [actionType, setActionType] = useState('all');
  const { entityName, auditLogs } = auditLogData || {};

  const actionTypeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Created', value: 'Provider' },
    { label: 'Error', value: 'Error' },
    { label: 'Expired', value: 'Expired' },
    { label: 'Verified', value: 'Verified' },
    { label: 'Initial', value: 'Initial' },
    { label: 'Submitted', value: 'Submitted' },
    { label: 'Manually Approved', value: 'ManuallyApproved' },
    { label: 'Manually Rejected', value: 'ManuallyRejected' },
  ];

  const handleChangeActionType = (value: string) => {
    setActionType(value);
  };

  const breadcrumbItems = [
    {
      title: <HomeLine className="h-5 w-5 text-gray-500" />,
      href: '/',
    },
    {
      title: 'Audit Log',
      className: 'font-semibold text-blue-600',
    },
  ];
  const filteredData = useMemo(() => {
    if (!auditLogs) return [];

    return auditLogs
      .filter((item) => {
        const itemDate = parseISO(item.timestamp ?? '');
        const dateInRange =
          !startDate || !endDate
            ? true
            : isWithinInterval(itemDate, {
                start: startOfDay(startDate),
                end: endOfDay(endDate),
              });

        const typeMatches = actionType === 'all' || item.eventType === actionType;
        return dateInRange && typeMatches;
      })
      .sort(
        (a, b) => new Date(b.timestamp ?? '').getTime() - new Date(a.timestamp ?? '').getTime()
      );
  }, [auditLogs, startDate, endDate, actionType]);

  const handleDateChange = (dates: [Date | null, Date | null] | null) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <div className="pt-8 h-screen">
      <div className="flex flex-col px-[22.5px] bg-white pb-2">
        <div className="w-full pb-5">
          <Breadcrumb items={breadcrumbItems} />
        </div>

        <span className="text-2xl text-grayModern-800 font-bold">{entityName}</span>
        <span className="text-gray-700 text-sm">
          Below is a log of all actions relevant to this customer, newest to oldest.
        </span>
      </div>

      <div className="bg-gray-100 h-full p-2.5 text-gray-800 text-sm font-medium">
        <div className="bg-white h-fit rounded-lg p-5">
          <div className="flex items-center pb-5">
            <span className="mr-2.5">Action type:</span>
            <BaseSelect
              options={actionTypeOptions}
              className="w-48 h-10"
              defaultValue="all"
              value={actionType}
              onChange={handleChangeActionType}
            />

            <DotsVerticalIcon className="text-gray-300 h-4" />

            <DatePicker.RangePicker
              format={'DD/MM/YYYY'}
              className="w-96 h-10"
              suffixIcon={<CalendarDate className="h-5 text-gray-700" />}
              onChange={(dates) =>
                handleDateChange(
                  dates ? [dates[0]?.toDate() ?? null, dates[1]?.toDate() ?? null] : null
                )
              }
              placeholder={['From', 'To']}
            />
          </div>

          <TableComponent
            columns={auditLogColumns}
            dataSource={filteredData}
            hasPagination={false}
            isLoading={isLoading}
            className="entities-table"
          />
        </div>
      </div>
    </div>
  );
};

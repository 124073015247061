import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { notification } from 'antd';
import { useAddIndividualMutation, useSearchIndividualMutation } from 'api/IndividualsApiSlice';
import { useModal } from 'components';
import { useDrawer } from 'components/Drawer/Drawer';
import { ModalComponent } from 'components/Modal/Modal';
import { format } from 'date-fns';
import { CreatePersonModel } from 'solstice-types';
import { removeWhiteSpaces } from 'utils';
import { AddNewIndividualContent } from '../AddNewIndividualContent';
import { IndividualDrawerFooter } from '../IndividualDrawerFooter';
import { AddNewAssociatedPartyContent } from './AddNewAssociatedPartyContent';
import { creatorId } from './IndividualConstants';

import './IndividualFooter.scss';

export const IndividualContent = () => {
  const [selectedIndividualParty, setSelectedIndividualParty] = useState<any | null>(null);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleSubmit, getValues } = methods;

  const onSubmit = (data: any) => {};

  const { closeDrawer } = useDrawer();
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();

  const [addIndividual, { isLoading }] = useAddIndividualMutation();
  const [searchIndividual, { data, isLoading: isSearching }] = useSearchIndividualMutation();

  const modalBodyText =
    'This individual already exists in Prime. You can either update the data for the existing individual or use the Search option to start the verification process.';

  const handleStartVerification = async (forceStore: boolean = false) => {
    try {
      const formValues = getValues();

      const formattedData = Object.keys(formValues)
        .filter((key) => key.startsWith('firstName_'))
        .map((key) => {
          const i = key.split('_')[1];
          const dateOfBirth = formValues[`dateOfBirth_${i}`];

          return {
            firstName: formValues[`firstName_${i}`] || '',
            lastName: formValues[`lastName_${i}`] || '',
            middleName: formValues[`middleName_${i}`] || '',
            email: formValues[`email_${i}`] || '',
            mobilePhone: removeWhiteSpaces(formValues[`mobilePhone_${i}`] || ''),
            roleId: formValues[`relationship_${i}`] || '',
            forceStore: forceStore || false,
            dateOfBirth: dateOfBirth ? format(new Date(dateOfBirth), 'yyyy-MM-dd') : '2000-01-01',
            country: 'AU',
            postcode: '2259',
            state: 'NSW',
            streetName: 'Oriana Street',
            streetNumber: '33',
            streetType: 'STREET',
            suburb: 'Yarramalog',
          };
        });

      const { firstName, lastName, email, mobilePhone, dateOfBirth, middleName, relationship } =
        formValues;
      const individualData = {
        roleId: creatorId,
        firstName: firstName || formattedData[0].firstName || '',
        lastName: lastName || formattedData[0].lastName || '',
        email: email || formattedData[0].email || '',
        middleName: middleName || formattedData[0].middleName || '',
        mobilePhone: removeWhiteSpaces(mobilePhone || '') || formattedData[0].mobilePhone,
        forceStore: forceStore || false,
        dateOfBirth:
          (dateOfBirth
            ? format(new Date(dateOfBirth), 'yyyy-MM-dd')
            : formattedData[0].dateOfBirth) || '2000-01-01',
        country: 'AU',
        postcode: '2259',
        state: 'NSW',
        streetName: 'Oriana Street',
        streetNumber: '33',
        streetType: 'STREET',
        suburb: 'Yarramalog',
      };

      const selectedPartyIndividualData = [
        {
          email,
          mobilePhone,
          middleName,
          firstName,
          lastName,
          relationship,
          dateOfBirth,
          forceStore: true,
        },
      ];

      const cleanedIndividualData = Object.keys(individualData).some(() => individualData)
        ? individualData
        : null;

      const payload: CreatePersonModel[] = [
        ...(cleanedIndividualData
          ? [
              {
                ...cleanedIndividualData,
                dateOfBirth: cleanedIndividualData.dateOfBirth
                  ? format(new Date(cleanedIndividualData.dateOfBirth), 'yyyy-MM-dd')
                  : '2000-01-01',
              },
            ]
          : []),
        ...formattedData.filter((data) => data.roleId !== ''),
        ...(selectedIndividualParty
          ? selectedPartyIndividualData.map((item) => ({
              ...item,
              dateOfBirth: item.dateOfBirth
                ? format(new Date(item.dateOfBirth), 'yyyy-MM-dd')
                : '2000-01-01',
            }))
          : []),
      ];

      await addIndividual({ newIndividual: payload, isGroup: false })
        .unwrap()
        .then((response: any) => {
          navigate(`/individual-details/${response[0]?.id}`);
        });

      closeDrawer();
      closeModal();

      notification.success({
        message: 'Verification link created!',
        className: 'custom-success-message',
        placement: 'top',
        closeIcon: <div className="flex items-center justify-center">X</div>,
      });
    } catch (error: any) {
      if (error.data.toLowerCase().includes('email')) {
        openModal(
          <ModalComponent
            bodyContent={modalBodyText}
            isLoading={isLoading}
            onCancel={closeModal}
            onUpdate={() => handleStartVerification(true)}
          />,
          'Duplicated Entity'
        );
      } else {
        notification.error({
          message: 'A general error has occurred, please contact IT support for assistance.',
          className: 'custom-error-message',
          placement: 'top',
          icon: <></>,
        });
      }
    }
  };

  return (
    <FormProvider {...methods} handleSubmit={() => handleSubmit(onSubmit)}>
      <div className="flex flex-col justify-between overflow-auto h-[calc(100vh-150px)] mb-[20px]">
        <div className="flex justify-start items-start flex-col ml-1.5 gap-[20px]">
          <span className="font-bold text-2xl text-gray-800">Start New Verification</span>
          <AddNewIndividualContent
            searchIndividual={searchIndividual}
            data={data}
            isLoading={isSearching}
          />

          <AddNewAssociatedPartyContent
            searchIndividual={searchIndividual}
            data={data ?? []}
            selectedIndividual={selectedIndividualParty}
            setSelectedIndividual={setSelectedIndividualParty}
            isLoading={isSearching}
          />
        </div>
      </div>
      <IndividualDrawerFooter
        handleSave={handleSubmit(() => handleStartVerification(false))}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};
